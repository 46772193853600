import React, { Component } from "react";
import {
  createStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Compliance from "../dashboard/Compliance";
import {
  CodeSample,
  createMemberSource,
  fundRollover,
  fundSearch,
  getBalanceSource,
  listMembersSource
} from "../dashboard/CodeSamples";
import APISandbox from "../dashboard/APISandbox";
import Icon from "../common/Icon";
import { onboardingWidth } from "../../Theme";
import cx from "classnames";

const complianceWidth = 1300;

const styles = (theme: Theme) =>
  createStyles({
    tab: {
      fontSize: 14,
      textTransform: "none",
      minWidth: 132,
      maxWidth: 132,
      width: 132,
      paddingBottom: theme.spacing(2)
    },
    tabIcon: {
      fontSize: 70
    },
    textContainer: {
      textAlign: "center",
      maxWidth: 750,
      marginLeft: "auto",
      marginRight: "auto"
    },
    heading: {
      fontSize: 20,
      color: "#000",
      marginBottom: theme.spacing(1)
    },
    subheading: {
      fontSize: 14,
      color: "#606164"
    },
    link: {
      color: "#000"
    },
    code: {
      marginTop: theme.spacing(6)
    },
    compliance: {
      marginTop: theme.spacing(6)
    },
    onboarding: {
      position: "relative",
      marginLeft: -((complianceWidth - onboardingWidth) / 2),
      marginRight: -((complianceWidth - onboardingWidth) / 2)
    },
    tabs: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    details: {
      marginTop: theme.spacing(4),
      color: theme.palette.grey[700],
      textAlign: "center"
    }
  });

interface State {
  selectedTab: number;
  tabSources: { [tabIndex: string]: CodeSample[] };
}

interface Props extends WithStyles<typeof styles> {
  onboarding?: boolean;
}

export class Prototyping extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: 0,
      tabSources: {
        0: [createMemberSource(), fundSearch, fundRollover],
        1: [getBalanceSource, listMembersSource]
      }
    };
  }

  render() {
    const { selectedTab, tabSources } = this.state;
    const { classes, onboarding } = this.props;

    return (
      <div>
        <Tabs
          value={selectedTab}
          onChange={this.selectTab}
          centered
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            icon={<Icon icon="customer" classes={{ root: classes.tabIcon }} />}
            label="Customer On-boarding"
            classes={{
              root: classes.tab
            }}
          />
          <Tab
            icon={<Icon icon="funds" classes={{ root: classes.tabIcon }} />}
            label="Members & Operations"
            classes={{
              root: classes.tab
            }}
          />
          <Tab
            icon={<Icon icon="security" classes={{ root: classes.tabIcon }} />}
            label="Security & Compliance"
            classes={{
              root: classes.tab
            }}
          />
        </Tabs>
        {selectedTab === 0 && (
          <div className={classes.textContainer}>
            <Typography className={classes.heading}>
              Get started by prototyping your customer on-boarding experience
              with Certane
            </Typography>
            <Typography className={classes.subheading}>
              The easiest way to get started with Certane is to try prototyping
              an engaging on-boarding experience for prospective members joining
              your fund.
            </Typography>
          </div>
        )}
        {selectedTab === 1 && (
          <div className={classes.textContainer}>
            <Typography className={classes.heading}>
              Managing operations and customers with Certane
            </Typography>
            <Typography className={classes.subheading}>
              Certane is focused on putting revenue, growth and data at the
              centre of your operations. Our API can securely provide key
              information, generate reports on key data and provide flexible
              integration with your software systems.
            </Typography>
          </div>
        )}
        {selectedTab === 2 && (
          <React.Fragment>
            <div className={classes.textContainer}>
              <Typography className={classes.heading}>
                Managing security and compliance with Sargon
              </Typography>
              <Typography className={classes.subheading}>
                In a complex and ever-changing environment, information security
                and compliant marketing and disclosures are critical to building
                engaging and efficient funds and financial products. Learn more
                about{" "}
                <a
                  href="https://www.sargon.com/products/arcadia"
                  target="_blank"
                  className={classes.link}
                >
                  Arcadia
                </a>
              </Typography>
            </div>
            <div
              className={cx(
                classes.compliance,
                onboarding && classes.onboarding
              )}
            >
              <Compliance />
            </div>
          </React.Fragment>
        )}
        {tabSources[selectedTab] && (
          <div className={cx(classes.code)}>
            <APISandbox
              id="default"
              title=""
              sources={tabSources[selectedTab]}
            />
          </div>
        )}

        {selectedTab < 2 && (
          <Typography className={classes.details}>
            Want to learn more? Check out our{" "}
            <a href="/api/" target="_blank" className={classes.link}>
              API Documentation
            </a>
          </Typography>
        )}
      </div>
    );
  }

  private selectTab = (event: any, selectedTab: number) => {
    this.setState({ selectedTab });
  };
}

export default withStyles(styles)(Prototyping);
